/*==========================================================================

	Template Elements Stylesheet
____________________________________________________________

	Client:		Bluewater Village Early Learning
	Date:		27/08/2018
	Author:		Edge Marketing Solutions

===========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

@import url('https://fonts.googleapis.com/css?family=Passion+One:400,700,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

/*===========================================================================*/
/*                        [Font Settings]                                  */
/*===========================================================================*/
				   $default-font: 'Open Sans', sans-serif;
				$decorative-font: 'Passion One', sans-serif;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
                  $color-primary: #00427a;
                $color-secondary: #88bae4;
                 $color-tertiary: #dbeaf7;
               $color-quaternary: darken($color-secondary, 20%);
                  	 $color-body: #414042;

                      $header-bg: transparent;
                $header-bg-color: $color-primary;
           $banner-overlay-color: #25a9e0;
    $feature-image-overlay-color: rgba($color-secondary, 0.8);
                 $slider-overlay: white;
             $slider-overlay-bar: rgba(255, 255, 255, 0.7);
     $slider-text-shadow-opacity: 1px 1px 1px rgba(0, 0, 0, 0.7);

/*===========================================================================*/
/*                    [Navigation Settings]                                  */
/*===========================================================================*/
					   $nav-font: $default-font;
                            $nav: $color-body;
                      $nav-hover: $color-secondary;
                   $nav-bg-color: $color-primary;
          $nav-menu-btn-bg-color: $color-primary;
                     $nav-border: rgba(255, 255, 255, 0.3);
            $nav-bg-color-mobile: $color-primary;
     $nav-bg-color-mobile-active: $color-secondary;
               $nav-color-mobile: white;

/*===========================================================================*/
/*                  [Enrol Panel Settings]                                   */
/*===========================================================================*/
              $enrol-panel-bg-color: white;
                 $enrol-panel-color: $color-primary;
                 $enrol-panel-hover: $color-secondary;

/*===========================================================================*/
/*                  [Enrol Button Settings]                                   */
/*===========================================================================*/
                 $enrol-btn-color: $color-primary;
                 $enrol-btn-hover: $color-secondary;
                    $enrol-btn-bg: white;
              $enrol-btn-bg-hover: $color-primary;
                $enrol-btn-border: $color-body;

/*===========================================================================*/
/*                  [Feature Navigation Settings]                            */
/*===========================================================================*/
                 $feature-nav-bg: transparent;
              $feature-nav-color: $color-body;
              $feature-nav-title: $color-body;
        $feature-nav-title-hover: $color-primary;
          $feature-nav-btn-color: $color-primary;
          $feature-nav-btn-hover: $color-primary;
    $feature-nav-btn-hover-color: white;
   $feature-nav-btn-border-color: $color-primary;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
                      $footer-bg: $color-primary;
                   $footer-color: rgba(255, 255, 255, 0.66);
				$footer-h6-color: rgba(255, 255, 255, 1);
                 $footer-a-color: $footer-color;
                 $footer-a-hover: rgba(255, 255, 255, 1);
				  $footer-border: rgba(white, 0.3);
                     $row-social: $color-secondary;
               $row-social-hover: white;

/*=========================================================================*/

/* [Global Template Styles] */

body
{
	color: $color-body;
	font-family: $default-font;
	font-weight: 300;
	font-size: 16px;
}

a
{
	transition: all 150ms ease-in-out;
	color: $color-primary;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: $color-secondary;
	}
}

img
{
	max-width: 100%;
}

img.svg-responsive
{
	width: 100% \9;
}

img.img-lightbox
{
	cursor: zoom-in;
}

h1, .h1 {
	font-family: $default-font;
	color: $color-primary;
	font-size: 58px;
	font-weight: 300;
	margin-bottom: 20px;
}

h2, .h2 {
	font-family: $default-font;
	font-weight: 300;
	color: $color-primary;
	font-size: 42px;

	a {
		color: $color-secondary;
	}
}

h3, .h3 {
	font-family: $default-font;
	font-weight: 700;
	color: $color-primary;
}

h4, .h4 {
	font-family: $default-font;
	color: $color-primary;
	font-weight: 400;
	margin-bottom: 1rem;
}

hr {
	border-top: 2px dotted $color-secondary;
	margin: 1rem 0;
}

p.lead
{
	color: $color-body;
	font-size: 24px;
}

.swiper-button-prev,
.swiper-button-next
{
	color: $color-primary;
}

.btn {
	font-weight: 300!important;
	padding: 0.45rem 1.5rem!important;
	border-radius: 0.8rem!important;
	font-size: 15px!important;
	font-family: $default-font!important;
}

.btn-bold
{
	font-weight: 700!important;
}

.btn-short {
	padding: 0.5rem 0.75rem;
}

.btn.btn-primary {
	background-color: $color-primary!important;
	border-color: $color-primary!important;

	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10)!important;
		border-color: darken($color-primary, 10)!important;
		color: white;
	}

	&:hover {
		background-color: white!important;
		color: black!important;
		border-color: black!important;
	}

	&:focus,
	&.focus,
	&:active
	{
		box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5)!important;
	}
}

a.btn-cloud {
	position: relative;
	max-width: 143px;
	max-height: 97px;
	padding: 28px 20px;
    font-size: 17px;
    font-weight: 600;
	color: white;
	text-align: center;
	line-height: 1.4rem;

	&.dark
	{
		background: transparent url('../images/btn-cloud-full-blue.svg') top center no-repeat;

		&:hover,
		&:active,
		&:focus
		{
			color: $color-primary;
			background: transparent url('../images/btn-cloud-full-light-blue.svg') top center no-repeat;
		}
	}

	&.light
	{
		background: transparent url('../images/btn-cloud-full-light-blue.svg') top center no-repeat;
		max-width: 178px;
		max-height: 118px;
		padding: 35px 23px;

		&:hover,
		&:active,
		&:focus
		{
			color: $color-primary;
		}
	}
}


.btn.btn-success {
	background-color: $color-secondary!important;
	border-color: $color-secondary!important;
	color: $color-primary;

	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-secondary, 10)!important;
		border-color: darken($color-secondary, 10)!important;
	}

	&:hover {
		background-color: white!important;
		color: black!important;
		border-color: black!important;
	}

	&:focus,
	&.focus,
	&:active
	{
		box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5)!important;
	}
}

.btn.btn-white {
	background-color: white!important;
	color: $color-primary!important;
	border-color: $color-primary!important;

	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10)!important;
		border-color: darken($color-primary, 10)!important;
	}

	&:hover {
		background-color: darken($color-primary, 20%)!important;
		color: white!important;
		border-color: $color-primary!important;
	}

	&:focus,
	&.focus,
	&:active
	{
		box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5)
	}
}

.btn.btn-yellow {
	background-color: $color-secondary!important;
	border-color: $color-secondary!important;
	color: $color-body!important;
	font-family: $default-font!important;

	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-secondary, 10)!important;
		border-color: darken($color-secondary, 10)!important;
	}

	&:hover {
		background-color: white!important;
		color: $color-primary!important;
		border-color: darken($color-primary, 10%)!important;
	}

	&:focus,
	&.focus,
	&:active
	{
		box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5)!important;
	}

	&.btn-lg
	{
		font-size: 20px!important;
		padding: 0.6rem 2rem!important;
		border-radius: 2em!important;
	}
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

@media (max-width: 1023px) {
	.btn.btn-yellow.btn-lg
	{
		font-size: 13px!important;
	}

	h1
	{
		font-size: 3rem;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {

	.swiper-container {
		.swiper-wrapper {
			&::before {
				width: 200px;
				height: 120px;
			}
		}
	}

	h2
	{
		font-size: 2rem;
	}

	//Bootstrap - hide only mobile
	.d-xs-none
	{
		display: none!important;
	}
}

/*=========================================================================*/

/* [Header Template Styles] */

.header {

	width: 100%;

	.h-logo
	{
		padding: 20px 0;
		text-align: center;

		img {
			width: 235px;
		}
	}

	#portal-selector
	{
		display: inline-block;
		width: auto;
		vertical-align:  middle;
		color: #A6A8AB;
		border-color: #e5e6e7;
		font-family: $default-font;
	}

	a .si {
		position: relative;
		top: -12px;
		color: $color-secondary;
		font-size: 2.4rem;

		&:hover {
			color: $color-primary;
		}
	}
}

.swiper-wrapper
{
	position: relative;
	z-index: 1;
}

.swiper-slide
{
	z-index: 1;

	img
	{
		width: 100%;
	}

	.free-care
	{
		width: 430px;
		height: 230px;
		display: block;
		position: absolute;
		bottom: -3px;
		right: 0;
		z-index: 0;
	}
}

.listing-logo
{
	max-width: 240px;
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.swiper-slide .free-care
	{
		width: 330px;
		height: auto;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

	.swiper-slide .free-care
	{
		width: 230px;
		height: auto;
	}
}

@media (max-width: 991px) {
	.free-care
	{
		width: 215px;
		height: 165px;
		bottom: -3px;
		right: 0;
	}

}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
	.header .h-logo
	{
		padding: 2rem 0;

		img {
			width: 230px;
		}
	}

	.swiper-slide:after
	{
		display: none;
	}
}

@media (max-width: 575px) {
	.header {
		position: relative;
		width: 100%;
		background: #fff;
		text-align: center;
	}

	.header .h-logo
	{
		padding: 3rem 0;

		img {
			width: 230px;
		}
	}
}

@media (max-width: 425px) {
	.header {
		.h-logo {
			width: 100%;
			text-align: center;

			img
			{
				right: 0;
			}
		}
	}
}

/*=========================================================================*/

/* [Navigation Template Styles] */

.navbar-light {
	font-family: $nav-font;
	color: $nav;
	font-size: 16px;
	font-weight: 300;
	padding: 0;
	margin-top: 32px!important;
}

.navbar-light .navbar-nav .active .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active
{
	color: $nav-hover;
}

.navbar-light .nav-item > .nav-link
{
	color: $nav;
	padding: 0.5rem;
	margin-left: 2rem;
	font-size: 16px;
}

.navbar-light .nav-item:first-child .nav-link
{
	padding-left: 0;
}

.navbar-light .nav-item:last-child .nav-link
{
	padding-right: 0;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .nav-item .active
{
	color: $nav-hover;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show
{
	color: $nav-hover;
}

.navbar-light .dropdown-item
{
	font-weight: 300;
}

.navbar-light .navbar-toggler
{
	width: 100%;
	padding: 15px 5px;

	background-color: $nav-bg-color-mobile;
	border-radius: 0;
	border: none;

	color: $nav-color-mobile;
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-light .nav-item > .nav-link
	{
		font-size: 0.9rem;
		margin-left: 1.0rem;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}


@media (min-width: 991px)
{
	.navbar-light .navbar-nav .dropdown-menu
	{
		left: 50%;
		transform: translateX(-50%);
		text-align: center;

		-webkit-box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);
		box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);
	}

	.navbar-light .dropdown-item.active,
	.navbar-light .dropdown-item:active,
	.navbar-light .dropdown-item:focus,
	.navbar-light .dropdown-item:hover
	{
		background-color: $color-primary;

		color: rgb(255, 255, 255);
	}

}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
	.nav-container
	{
		width: auto;
		min-width: 250px;
		max-height: 165px;
	}

	.navbar-light
	{
		background: white;
		margin: 16px 0 !important;
	}

	.navbar-light .navbar-nav
	{
		display: block;

		border: 1px solid $color-primary;
	}

	.navbar-light .nav-item > .nav-link
	{
		padding: 10px 0;

		margin: 0;

		font-size: 17px;

		text-align: center;

		color: $color-body;
	}

	.navbar-nav .dropdown-menu
	{
		border-radius: 0;
		-webkit-box-shadow:inset 0 0 20px 1px rgba(0,0,0,0.1);
		box-shadow:inset 0 0 20px 1px rgba(0,0,0,0.1);
	}

	.navbar-nav .dropdown-item
	{
		text-align: center;
	}

	.navbar-light .navbar-nav .nav-link:hover,
	.navbar-light .navbar-nav .nav-link:focus
	{
		color: $color-primary;
	}

	.navbar-light .nav-item .active
	{
		color: white;
	}

	.navbar-light .dropdown-item.active,
	.navbar-light .dropdown-item:active,
	.navbar-light .dropdown-item:focus,
	.navbar-light .dropdown-item:hover
	{
		background-color: $color-primary;

		color: rgb(255, 255, 255);
	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show
	{
		background: $color-secondary;
		color: $color-primary;
	}
}

@media (max-width: 768px) {
	#portal-selector
	{
		width: 100%!important;
	}
}

@media (max-width: 575px) {
	.nav-container
	{
		background-color: $color-primary;
		width: 100%;

		padding: 0!important;
	}

}

.nav > .nav-item.active > a
{
	color: $color-secondary;
}

/*=========================================================================*/

/* [Page Template Styles] */

.page
{
	width: 100%;
}

.page-subpage
{
	padding-bottom: 40px;
	width: 80%;
	float: right;
}

.subpage-banner {
	position: relative;
	overflow: hidden;

	&:before
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		background-image: url('../images/cloud-overlay-top.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}

	&:after
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		bottom: 0;
		background-image: url('../images/cloud-overlay-bottom.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}
}

.swiper-container.rotator, .swiper-container {
	position: relative;
	overflow: hidden;

	.overlay
	{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 40vw;
		opacity: 0.7;
		z-index: 90;
	}
}

.swiper-container.portal_rotator .swiper-slide {
	&::before
	{
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(to top, $banner-overlay-color 0%, transparentize($banner-overlay-color, 1) 40%, transparentize($banner-overlay-color, 1) 100%);
		z-index: 90;
	}

	.overlay
	{
		width: 37vw;
		opacity: 0.2;
	}
}

.rotator-overlay
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: '';
	background: -moz-linear-gradient(left, rgba($banner-overlay-color,1) 0%, rgba($banner-overlay-color,0) 80%, rgba($banner-overlay-color,0) 100%);
	background: -webkit-linear-gradient(left, rgba($banner-overlay-color,1) 0%,rgba($banner-overlay-color,0) 80%,rgba($banner-overlay-color,0) 100%);
	background: linear-gradient(to right, rgba($banner-overlay-color,1) 0%,rgba($banner-overlay-color,0) 80%,rgba($banner-overlay-color,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25a9e0', endColorstr='#0025a9e0',GradientType=1 );

	&:before
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		background-image: url('../images/cloud-overlay-top.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}

	&:after
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		bottom: 0;
		background-image: url('../images/cloud-overlay-bottom.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}

	.croc
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: '';
		z-index: 1;

		&:after
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
			background: url('../images/croc.svg') top left no-repeat;
		}
	}
}

.enquire-btn
{
	width: 100%;
	height: 80px;
	background: transparent url('../images/btn-cloud-half-blue.svg') top center no-repeat;
	background-size: cover;

	text-align: center;
	color: white;
	font-weight: 600;
	font-size: 18px;
	line-height: 3rem;
}

@media (min-width: 1024px) and (max-width: 1199px) {

}

@media (max-width: 1023px) {

}

@media (max-width: 768px) {
	.subpage-banner
	{
		margin-bottom: 1rem !important;
	}

	.subpage-banner:after
	{
		display: none;
	}
}

.homepage
{
	padding: 2rem 0 3rem;

	position: relative;
	overflow: hidden;
}

.page
{
	width: 100%;
	padding: 30px 0;
}

.front-contents
{
	position: relative;

	color: white;

	h1
	{
		color: white;
		font-family: $default-font;
		font-size: 80px;
		line-height: 1.2em;
		margin: 0.2em 0 3rem;
	}

	.lead
	{
		color: $color-secondary;
	}
}

.page-contents
{
	display: flex;
	position: relative;
}

.homepage-contents
{
	background: $color-tertiary;
	padding: 0;
	display: flex;
	position: relative;

	&:before
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		background-image: url('../images/cloud-overlay-top.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 100;
	}

	&:after
	{
		content: ' ';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		bottom: 0;
		background-image: url('../images/cloud-overlay-bottom.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 100;
	}

	.homepage-image
	{
		flex-basis: 40%;
		position: relative;
		z-index: 0;
		background-size: cover;

		&:before
		{
			content: ' ';
			width: 25%;
			height: 100%;
			display: block;
		}
	}

	.homepage-text
	{
		flex-basis: 60%;
		padding: 120px;
		position: relative;
		margin-bottom: 40px;

		h1
		{
			font-size: 58px;
			font-weight: 300;
		}
	}
}

/* [Feature Button Styles] */

.feature-nav
{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	margin: 10px 0;
	background: $feature-nav-bg;
	color: $feature-nav-color;
}

.feature-nav .feature-nav-item
{
	flex-basis: 33.33%;
	position: relative;
	z-index: 1;
	padding: 20px 40px;

	background-size: cover;
	background-repeat: no-repeat;

	text-align: left;
	font-size: 28px;
}

.feature-nav .feature-nav-item i
{
	color: white;
	margin-top: 15px;
	margin-left: 1px;
	transition: all 150ms ease-in-out;

	&:hover,
	&:active,
	&:focus
	{
		color: $color-secondary;
	}
}

.feature-nav .feature-nav-item p
{
	color: $feature-nav-color;
	font-family: $default-font;
	font-size: 17px;
	font-weight: 400;
}

.feature-nav .feature-nav-item a.title
{
	font-size: 40px;
	font-family: $default-font;
	color: $feature-nav-title;

	&:hover
	{
		color: $feature-nav-title-hover;
	}
}

.feature-nav .feature-nav-icon
{
	width: 116px;
	height: 78px;
	display: block;
	margin: 0 auto 20px;

	font-size: 45px;
	background: transparent url('../images/btn-cloud-full-blue.svg') top center no-repeat;
	background-size: cover;
}

.feature-nav .feature-nav-item:hover:before
{
	background-color: rgba(0, 0, 0, 0);
}

.feature-nav .feature-nav-item .content
{
	width: 100%;
}

.feature-nav .feature-nav-item img
{
	max-width: 70%;
	max-height: 110px;
}

.cloud-blue
{
	margin: 0;
	width: 100%;
	height: 40px;
	display: block;
	background-size: cover;
	background-repeat: no-repeat;

	&.top
	{
		background-image: url('../images/cloud-blue-overlay-bottom.svg');
	}

	&.bottom
	{
		background-image: url('../images/cloud-blue-overlay-top.svg');
	}
}


/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.feature-nav .feature-nav-item
	{
		flex-basis: 50%;

		font-size: 24px;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
	.feature-nav .feature-nav-item
	{
		text-align: center;
	}

	.feature-nav .feature-nav-item img
	{
		max-width: 70%;
		max-height: 90px;
	}

	.feature-nav .feature-nav-item p
	{
		font-size: 16px;
		line-height: 1.5em;
		width: 100%;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
	.feature-nav
	{
		padding: 20px;
	}

	.feature-nav .feature-nav-item
	{
		flex-basis: 100%;
		min-height: 130px;
		margin-bottom: 10px;

		font-size: 24px;
		text-align: center;
	}

	.feature-nav .feature-nav-item img
	{
		max-width: 70%;
		max-height: 90px;
	}

	.feature-nav .feature-nav-item p
	{
		font-size: 16px;
		line-height: 1.5em;
		width: 100%;
	}

	h1,
	.front-contents h1
	{
		font-size: 45px;
	}

	p.lead
	{
		font-size: 18px;
		font-weight: 700;
		padding: 1em 0;
	}
}

@media (max-width: 425px) {

}

.highlight-panel
{
	position: relative;
	padding:20px 0;

	color: $enrol-panel-color;
	text-align: left;
	font-size: 22px;
	font-family: $default-font;
	font-weight: 300;

	background-size: cover;
	z-index: 1;

	.container
	{
		z-index: 5;
		position: relative;

		h3 {
			margin: 0;
			font-family: $default-font;
			font-size: 40px;
			font-weight: 600;
			z-index: 1;
		}

		p
		{
			padding: 0!important;
			margin: 0!important;
			line-height: 2rem;
		}

		.fa
		{
			font-size: 60px;

			color: $color-secondary;
		}

		.btn-primary:hover
		{
			background-color: rgb(255, 255, 255);
		}

		&.testimonial
		{
			color: white;
			text-align: center;
			font-family: $default-font;
		}

		.fa
		{
			color: $color-primary;
		}

	}

}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Large desktop */
@media (min-width: 1024px) {
	.croc-girl
	{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		display: block;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background: transparent url('../images/croc-and-girl.svg') top right no-repeat;
		z-index: -1;
	}

	.windmill
	{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		display: block;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background: transparent url('../images/windmill.svg') 100px top no-repeat;
		z-index: -1;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
	.page
	{
		padding: 0 0 90px 0;
	}

	.highlight-panel
	{
		font-size: 16px;
	}

	.highlight-panel .fa
	{
		font-size: 50px;
	}

	.page-homepage
	{
		padding: 0;
	}

	.page-homepage .page-contents
	{
		padding: 0;
		display: block;
	}

	.swiper-text
	{
		position: relative;
		color: rgb(255, 255, 255);
	}

	.swiper-text .title
	{
		font-size: 17px;
	}

	.swiper-text .subtitle
	{
		font-size: 15px;
	}

	.subpage-banner {
		margin: 0;
	}

	.page-homepage .homepage-image
	{
		width: 100%;
		height: 250px;
	}

	.page-homepage .homepage-content
	{
		width: 100%;
		padding: 30px;

		font-size: 18px;
	}
}

/* Desktop */
@media (min-width: 992px) {
	.swiper-text
	{
		position: absolute;
		top: 100px;
		right: 200px;
		color: white;
		font-family: $decorative-font;
		font-size: 70px;
		font-weight: 700;
		text-shadow: 0 0 5px rgba(0,0,0,0.33);
		z-index: 10;
	}
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
	.highlight-panel
	{
		text-align: center;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {

}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {

	.homepage-contents
	{
		padding: 0;
		display: block;
	}

	.homepage-contents .homepage-image
	{
		width: 100%;
		height: 250px;
	}

	.homepage-contents .homepage-text
	{
		width: 100%;
		padding: 80px 30px 30px 30px;

		font-size: 18px;
	}
}

/*=========================================================================*/

/* [Testimonial Template Styles] */

.staff-profile
{
	padding: 1em 0;
	color: $color-body;

	h2 {
		margin-bottom: 20px;

		a {
			color: $color-secondary;

			&:hover,
			&:active,
			&:focus {
				color: $color-primary;
			}
		}
	}
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {

}

/*=========================================================================*/

/* [Testimonial Template Styles] */

.testimonial
{
	background: $color-tertiary;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	text-shadow: 0 1px 0 #dbeaf7;

	.quotation-marks {
		max-width: 5rem;
	}

	.testie-title
	{
		font-size: 22px;
		font-weight: 600;
	}
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {

}

/*=========================================================================*/

/* [Footer Template Styles] */

.footer
{
	position: relative;
	width: 100%;
	padding: 80px 0 20px;

	background-color: $color-primary;

	color: $footer-color;
	font-size: 12px;
	font-weight: 400;
	z-index: 1;

	&:before
	{
		content: '';
		width: 100%;
		height: 40px;
		display: block;
		position: absolute;
		top: 0;
		background-image: url('../images/cloud-overlay-top.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
	}

	&.footer-blue
	{
		&:after
		{
			content: '';
			width: 100%;
			height: 40px;
			display: block;
			position: absolute;
			top: 0;
			background-image: url('../images/cloud-blue-overlay-top.svg');
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 1;
		}
	}

	a
	{
		color: $footer-a-color;

		&:hover
		{
			color: $footer-a-hover;
			text-decoration: none;
		}
	}

	h6
	{
		margin: 0 0 14px 0;

		color: $footer-h6-color;
		font-size: 14px;
		font-weight: 700;
	}

	ul
	{
		margin: 0;
		padding: 0;

		list-style-type: none;
	}

	ul > li
	{
		padding: 6px 0;
		border-top: 1px solid $footer-border;
	}

	.si
	{
		position: relative;
		color: $row-social;
		font-size: 3.5em;
		top: -8px;

		&:hover
		{
			color: $row-social-hover;
		}
	}

	.btn {
		color: white;
	}

	.footnotes
	{
		color: $footer-a-color;
		padding-top: 30px;

		opacity: 0.5;

		font-size: 12px;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.footer .btn-social
	{
		margin: 10px;
	}

	.footer .f-hours strong
	{
		display: block;
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.footer
	{
		padding: 20px 10px 50px 0;

		text-align: center;
	}

	.footer h6
	{
		margin: 30px 0 5px 0;

		font-weight: bold;
	}

	.footer .f-actions
	{
		padding-top: 40px;
		text-align: center;
	}
}

/*=========================================================================*/

/* [Table as row override Styles] */

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
	content: " ";
	display: table;
	clear: both;
}

table.table-as-row > tbody > tr {
	height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
	display: block;
	width: auto;
}

table.table-as-row > tbody > tr {
	display: block;
	width: auto;
	margin-right: -15px;
	margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
	display: block;
	height: auto !important;
	margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
	table.table-as-row > tbody > tr > td[class*=col-lg-] {
		float: left;
	}
}

/* col-md */
@media (min-width: 992px) {
	table.table-as-row > tbody > tr > td[class*=col-md-] {
		float: left;
	}
}

/* col-sm */
@media (min-width: 768px) {
	table.table-as-row > tbody > tr > td[class*=col-sm-] {
		float: left;
	}
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
	float: left;
}