/*==========================================================================

	Template Elements Stylesheet
____________________________________________________________

	Client:		Bluewater Village Early Learning
	Date:		27/08/2018
	Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
@import url("https://fonts.googleapis.com/css?family=Passion+One:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
/*===========================================================================*/
/*                        [Font Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                    [Navigation Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Enrol Panel Settings]                                   */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Enrol Button Settings]                                   */
/*===========================================================================*/
/*===========================================================================*/
/*                  [Feature Navigation Settings]                            */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #414042;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

a {
  transition: all 150ms ease-in-out;
  color: #00427a;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  color: #88bae4;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

h1, .h1 {
  font-family: "Open Sans", sans-serif;
  color: #00427a;
  font-size: 58px;
  font-weight: 300;
  margin-bottom: 20px;
}

h2, .h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #00427a;
  font-size: 42px;
}

h2 a, .h2 a {
  color: #88bae4;
}

h3, .h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #00427a;
}

h4, .h4 {
  font-family: "Open Sans", sans-serif;
  color: #00427a;
  font-weight: 400;
  margin-bottom: 1rem;
}

hr {
  border-top: 2px dotted #88bae4;
  margin: 1rem 0;
}

p.lead {
  color: #414042;
  font-size: 24px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #00427a;
}

.btn {
  font-weight: 300 !important;
  padding: 0.45rem 1.5rem !important;
  border-radius: 0.8rem !important;
  font-size: 15px !important;
  font-family: "Open Sans", sans-serif !important;
}

.btn-bold {
  font-weight: 700 !important;
}

.btn-short {
  padding: 0.5rem 0.75rem;
}

.btn.btn-primary {
  background-color: #00427a !important;
  border-color: #00427a !important;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #002647 !important;
  border-color: #002647 !important;
  color: white;
}

.btn.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 66, 122, 0.5) !important;
}

a.btn-cloud {
  position: relative;
  max-width: 143px;
  max-height: 97px;
  padding: 28px 20px;
  font-size: 17px;
  font-weight: 600;
  color: white;
  text-align: center;
  line-height: 1.4rem;
}

a.btn-cloud.dark {
  background: transparent url("../images/btn-cloud-full-blue.svg") top center no-repeat;
}

a.btn-cloud.dark:hover, a.btn-cloud.dark:active, a.btn-cloud.dark:focus {
  color: #00427a;
  background: transparent url("../images/btn-cloud-full-light-blue.svg") top center no-repeat;
}

a.btn-cloud.light {
  background: transparent url("../images/btn-cloud-full-light-blue.svg") top center no-repeat;
  max-width: 178px;
  max-height: 118px;
  padding: 35px 23px;
}

a.btn-cloud.light:hover, a.btn-cloud.light:active, a.btn-cloud.light:focus {
  color: #00427a;
}

.btn.btn-success {
  background-color: #88bae4 !important;
  border-color: #88bae4 !important;
  color: #00427a;
}

.btn.btn-success:not(:disabled):not(.disabled):active {
  background-color: #5ea2db !important;
  border-color: #5ea2db !important;
}

.btn.btn-success:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn.btn-success:focus, .btn.btn-success.focus, .btn.btn-success:active {
  box-shadow: 0 0 0 0.2rem rgba(136, 186, 228, 0.5) !important;
}

.btn.btn-white {
  background-color: white !important;
  color: #00427a !important;
  border-color: #00427a !important;
}

.btn.btn-white:not(:disabled):not(.disabled):active {
  background-color: #002647 !important;
  border-color: #002647 !important;
}

.btn.btn-white:hover {
  background-color: #000b14 !important;
  color: white !important;
  border-color: #00427a !important;
}

.btn.btn-white:focus, .btn.btn-white.focus, .btn.btn-white:active {
  box-shadow: 0 0 0 0.2rem rgba(136, 186, 228, 0.5);
}

.btn.btn-yellow {
  background-color: #88bae4 !important;
  border-color: #88bae4 !important;
  color: #414042 !important;
  font-family: "Open Sans", sans-serif !important;
}

.btn.btn-yellow:not(:disabled):not(.disabled):active {
  background-color: #5ea2db !important;
  border-color: #5ea2db !important;
}

.btn.btn-yellow:hover {
  background-color: white !important;
  color: #00427a !important;
  border-color: #002647 !important;
}

.btn.btn-yellow:focus, .btn.btn-yellow.focus, .btn.btn-yellow:active {
  box-shadow: 0 0 0 0.2rem rgba(136, 186, 228, 0.5) !important;
}

.btn.btn-yellow.btn-lg {
  font-size: 20px !important;
  padding: 0.6rem 2rem !important;
  border-radius: 2em !important;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (max-width: 1023px) {
  .btn.btn-yellow.btn-lg {
    font-size: 13px !important;
  }
  h1 {
    font-size: 3rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .swiper-container .swiper-wrapper::before {
    width: 200px;
    height: 120px;
  }
  h2 {
    font-size: 2rem;
  }
  .d-xs-none {
    display: none !important;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
}

.header .h-logo {
  padding: 20px 0;
  text-align: center;
}

.header .h-logo img {
  width: 235px;
}

.header #portal-selector {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  color: #A6A8AB;
  border-color: #e5e6e7;
  font-family: "Open Sans", sans-serif;
}

.header a .si {
  position: relative;
  top: -12px;
  color: #88bae4;
  font-size: 2.4rem;
}

.header a .si:hover {
  color: #00427a;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
}

.swiper-slide {
  z-index: 1;
}

.swiper-slide img {
  width: 100%;
}

.swiper-slide .free-care {
  width: 430px;
  height: 230px;
  display: block;
  position: absolute;
  bottom: -3px;
  right: 0;
  z-index: 0;
}

.listing-logo {
  max-width: 240px;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .swiper-slide .free-care {
    width: 330px;
    height: auto;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .swiper-slide .free-care {
    width: 230px;
    height: auto;
  }
}

@media (max-width: 991px) {
  .free-care {
    width: 215px;
    height: 165px;
    bottom: -3px;
    right: 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .header .h-logo {
    padding: 2rem 0;
  }
  .header .h-logo img {
    width: 230px;
  }
  .swiper-slide:after {
    display: none;
  }
}

@media (max-width: 575px) {
  .header {
    position: relative;
    width: 100%;
    background: #fff;
    text-align: center;
  }
  .header .h-logo {
    padding: 3rem 0;
  }
  .header .h-logo img {
    width: 230px;
  }
}

@media (max-width: 425px) {
  .header .h-logo {
    width: 100%;
    text-align: center;
  }
  .header .h-logo img {
    right: 0;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-light {
  font-family: "Open Sans", sans-serif;
  color: #414042;
  font-size: 16px;
  font-weight: 300;
  padding: 0;
  margin-top: 32px !important;
}

.navbar-light .navbar-nav .active .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active {
  color: #88bae4;
}

.navbar-light .nav-item > .nav-link {
  color: #414042;
  padding: 0.5rem;
  margin-left: 2rem;
  font-size: 16px;
}

.navbar-light .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-light .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .nav-item .active {
  color: #88bae4;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show {
  color: #88bae4;
}

.navbar-light .dropdown-item {
  font-weight: 300;
}

.navbar-light .navbar-toggler {
  width: 100%;
  padding: 15px 5px;
  background-color: #00427a;
  border-radius: 0;
  border: none;
  color: white;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .nav-item > .nav-link {
    font-size: 0.9rem;
    margin-left: 1.0rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 991px) {
  .navbar-light .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  }
  .navbar-light .dropdown-item.active,
  .navbar-light .dropdown-item:active,
  .navbar-light .dropdown-item:focus,
  .navbar-light .dropdown-item:hover {
    background-color: #00427a;
    color: white;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .nav-container {
    width: auto;
    min-width: 250px;
    max-height: 165px;
  }
  .navbar-light {
    background: white;
    margin: 16px 0 !important;
  }
  .navbar-light .navbar-nav {
    display: block;
    border: 1px solid #00427a;
  }
  .navbar-light .nav-item > .nav-link {
    padding: 10px 0;
    margin: 0;
    font-size: 17px;
    text-align: center;
    color: #414042;
  }
  .navbar-nav .dropdown-menu {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-nav .dropdown-item {
    text-align: center;
  }
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: #00427a;
  }
  .navbar-light .nav-item .active {
    color: white;
  }
  .navbar-light .dropdown-item.active,
  .navbar-light .dropdown-item:active,
  .navbar-light .dropdown-item:focus,
  .navbar-light .dropdown-item:hover {
    background-color: #00427a;
    color: white;
  }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show {
    background: #88bae4;
    color: #00427a;
  }
}

@media (max-width: 768px) {
  #portal-selector {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .nav-container {
    background-color: #00427a;
    width: 100%;
    padding: 0 !important;
  }
}

.nav > .nav-item.active > a {
  color: #88bae4;
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
}

.page-subpage {
  padding-bottom: 40px;
  width: 80%;
  float: right;
}

.subpage-banner {
  position: relative;
  overflow: hidden;
}

.subpage-banner:before {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url("../images/cloud-overlay-top.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.subpage-banner:after {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  background-image: url("../images/cloud-overlay-bottom.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.swiper-container.rotator, .swiper-container {
  position: relative;
  overflow: hidden;
}

.swiper-container.rotator .overlay, .swiper-container .overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40vw;
  opacity: 0.7;
  z-index: 90;
}

.swiper-container.portal_rotator .swiper-slide::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, #25a9e0 0%, rgba(37, 169, 224, 0) 40%, rgba(37, 169, 224, 0) 100%);
  z-index: 90;
}

.swiper-container.portal_rotator .swiper-slide .overlay {
  width: 37vw;
  opacity: 0.2;
}

.rotator-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: -moz-linear-gradient(left, #25a9e0 0%, rgba(37, 169, 224, 0) 80%, rgba(37, 169, 224, 0) 100%);
  background: -webkit-linear-gradient(left, #25a9e0 0%, rgba(37, 169, 224, 0) 80%, rgba(37, 169, 224, 0) 100%);
  background: linear-gradient(to right, #25a9e0 0%, rgba(37, 169, 224, 0) 80%, rgba(37, 169, 224, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25a9e0', endColorstr='#0025a9e0',GradientType=1 );
}

.rotator-overlay:before {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url("../images/cloud-overlay-top.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.rotator-overlay:after {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  background-image: url("../images/cloud-overlay-bottom.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.rotator-overlay .croc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  z-index: 1;
}

.rotator-overlay .croc:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: url("../images/croc.svg") top left no-repeat;
}

.enquire-btn {
  width: 100%;
  height: 80px;
  background: transparent url("../images/btn-cloud-half-blue.svg") top center no-repeat;
  background-size: cover;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 3rem;
}

@media (max-width: 768px) {
  .subpage-banner {
    margin-bottom: 1rem !important;
  }
  .subpage-banner:after {
    display: none;
  }
}

.homepage {
  padding: 2rem 0 3rem;
  position: relative;
  overflow: hidden;
}

.page {
  width: 100%;
  padding: 30px 0;
}

.front-contents {
  position: relative;
  color: white;
}

.front-contents h1 {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 80px;
  line-height: 1.2em;
  margin: 0.2em 0 3rem;
}

.front-contents .lead {
  color: #88bae4;
}

.page-contents {
  display: flex;
  position: relative;
}

.homepage-contents {
  background: #dbeaf7;
  padding: 0;
  display: flex;
  position: relative;
}

.homepage-contents:before {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url("../images/cloud-overlay-top.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;
}

.homepage-contents:after {
  content: ' ';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  background-image: url("../images/cloud-overlay-bottom.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;
}

.homepage-contents .homepage-image {
  flex-basis: 40%;
  position: relative;
  z-index: 0;
  background-size: cover;
}

.homepage-contents .homepage-image:before {
  content: ' ';
  width: 25%;
  height: 100%;
  display: block;
}

.homepage-contents .homepage-text {
  flex-basis: 60%;
  padding: 120px;
  position: relative;
  margin-bottom: 40px;
}

.homepage-contents .homepage-text h1 {
  font-size: 58px;
  font-weight: 300;
}

/* [Feature Button Styles] */
.feature-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 10px 0;
  background: transparent;
  color: #414042;
}

.feature-nav .feature-nav-item {
  flex-basis: 33.33%;
  position: relative;
  z-index: 1;
  padding: 20px 40px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: left;
  font-size: 28px;
}

.feature-nav .feature-nav-item i {
  color: white;
  margin-top: 15px;
  margin-left: 1px;
  transition: all 150ms ease-in-out;
}

.feature-nav .feature-nav-item i:hover, .feature-nav .feature-nav-item i:active, .feature-nav .feature-nav-item i:focus {
  color: #88bae4;
}

.feature-nav .feature-nav-item p {
  color: #414042;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.feature-nav .feature-nav-item a.title {
  font-size: 40px;
  font-family: "Open Sans", sans-serif;
  color: #414042;
}

.feature-nav .feature-nav-item a.title:hover {
  color: #00427a;
}

.feature-nav .feature-nav-icon {
  width: 116px;
  height: 78px;
  display: block;
  margin: 0 auto 20px;
  font-size: 45px;
  background: transparent url("../images/btn-cloud-full-blue.svg") top center no-repeat;
  background-size: cover;
}

.feature-nav .feature-nav-item:hover:before {
  background-color: rgba(0, 0, 0, 0);
}

.feature-nav .feature-nav-item .content {
  width: 100%;
}

.feature-nav .feature-nav-item img {
  max-width: 70%;
  max-height: 110px;
}

.cloud-blue {
  margin: 0;
  width: 100%;
  height: 40px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}

.cloud-blue.top {
  background-image: url("../images/cloud-blue-overlay-bottom.svg");
}

.cloud-blue.bottom {
  background-image: url("../images/cloud-blue-overlay-top.svg");
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .feature-nav .feature-nav-item {
    flex-basis: 50%;
    font-size: 24px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .feature-nav .feature-nav-item {
    text-align: center;
  }
  .feature-nav .feature-nav-item img {
    max-width: 70%;
    max-height: 90px;
  }
  .feature-nav .feature-nav-item p {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
  .feature-nav {
    padding: 20px;
  }
  .feature-nav .feature-nav-item {
    flex-basis: 100%;
    min-height: 130px;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
  }
  .feature-nav .feature-nav-item img {
    max-width: 70%;
    max-height: 90px;
  }
  .feature-nav .feature-nav-item p {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
  }
  h1,
  .front-contents h1 {
    font-size: 45px;
  }
  p.lead {
    font-size: 18px;
    font-weight: 700;
    padding: 1em 0;
  }
}

.highlight-panel {
  position: relative;
  padding: 20px 0;
  color: #00427a;
  text-align: left;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  background-size: cover;
  z-index: 1;
}

.highlight-panel .container {
  z-index: 5;
  position: relative;
}

.highlight-panel .container h3 {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  font-weight: 600;
  z-index: 1;
}

.highlight-panel .container p {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 2rem;
}

.highlight-panel .container .fa {
  font-size: 60px;
  color: #88bae4;
}

.highlight-panel .container .btn-primary:hover {
  background-color: white;
}

.highlight-panel .container.testimonial {
  color: white;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.highlight-panel .container .fa {
  color: #00427a;
}

/* Large desktop */
/* Large desktop */
@media (min-width: 1024px) {
  .croc-girl {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: transparent url("../images/croc-and-girl.svg") top right no-repeat;
    z-index: -1;
  }
  .windmill {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: transparent url("../images/windmill.svg") 100px top no-repeat;
    z-index: -1;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
  .page {
    padding: 0 0 90px 0;
  }
  .highlight-panel {
    font-size: 16px;
  }
  .highlight-panel .fa {
    font-size: 50px;
  }
  .page-homepage {
    padding: 0;
  }
  .page-homepage .page-contents {
    padding: 0;
    display: block;
  }
  .swiper-text {
    position: relative;
    color: white;
  }
  .swiper-text .title {
    font-size: 17px;
  }
  .swiper-text .subtitle {
    font-size: 15px;
  }
  .subpage-banner {
    margin: 0;
  }
  .page-homepage .homepage-image {
    width: 100%;
    height: 250px;
  }
  .page-homepage .homepage-content {
    width: 100%;
    padding: 30px;
    font-size: 18px;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .swiper-text {
    position: absolute;
    top: 100px;
    right: 200px;
    color: white;
    font-family: "Passion One", sans-serif;
    font-size: 70px;
    font-weight: 700;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.33);
    z-index: 10;
  }
}

/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .highlight-panel {
    text-align: center;
  }
}

/* Landscape phone to portrait tablet */
/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .homepage-contents {
    padding: 0;
    display: block;
  }
  .homepage-contents .homepage-image {
    width: 100%;
    height: 250px;
  }
  .homepage-contents .homepage-text {
    width: 100%;
    padding: 80px 30px 30px 30px;
    font-size: 18px;
  }
}

/*=========================================================================*/
/* [Testimonial Template Styles] */
.staff-profile {
  padding: 1em 0;
  color: #414042;
}

.staff-profile h2 {
  margin-bottom: 20px;
}

.staff-profile h2 a {
  color: #88bae4;
}

.staff-profile h2 a:hover, .staff-profile h2 a:active, .staff-profile h2 a:focus {
  color: #00427a;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Testimonial Template Styles] */
.testimonial {
  background: #dbeaf7;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-shadow: 0 1px 0 #dbeaf7;
}

.testimonial .quotation-marks {
  max-width: 5rem;
}

.testimonial .testie-title {
  font-size: 22px;
  font-weight: 600;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  position: relative;
  width: 100%;
  padding: 80px 0 20px;
  background-color: #00427a;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  font-weight: 400;
  z-index: 1;
}

.footer:before {
  content: '';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url("../images/cloud-overlay-top.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.footer.footer-blue:after {
  content: '';
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  background-image: url("../images/cloud-blue-overlay-top.svg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.footer a {
  color: rgba(255, 255, 255, 0.66);
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer h6 {
  margin: 0 0 14px 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 6px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.footer .si {
  position: relative;
  color: #88bae4;
  font-size: 3.5em;
  top: -8px;
}

.footer .si:hover {
  color: white;
}

.footer .btn {
  color: white;
}

.footer .footnotes {
  color: rgba(255, 255, 255, 0.66);
  padding-top: 30px;
  opacity: 0.5;
  font-size: 12px;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer .btn-social {
    margin: 10px;
  }
  .footer .f-hours strong {
    display: block;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 20px 10px 50px 0;
    text-align: center;
  }
  .footer h6 {
    margin: 30px 0 5px 0;
    font-weight: bold;
  }
  .footer .f-actions {
    padding-top: 40px;
    text-align: center;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
